<template>
    <div class="tech-warp">
      <erji-carousel></erji-carousel>
      <!-- 总体框架-->
       <div class="ab-text">
            <div class="ab-title">
                <p>—— 总体框架 ——</p>
            </div>
       </div>
      <div class="yz-img-con">
         <img src="../../../assets/images/fr01.png">
      </div>
       <!-- 产教融合新业态-->
       <div class="ab-text">
            <div class="ab-title">
                <p>—— 产教融合新业态 ——</p>
            </div>
       </div>
      <div class="yz-img-con">
         <img src="../../../assets/images/fro02.png">
      </div>
       <!-- 能力建设-->
       <div class="ab-text">
            <div class="ab-title">
                <p>—— 能力建设 ——</p>
            </div>
       </div>
       <div class="mode-top">
         <div class="mode-item left">
           <p class="mode-title">产业能力</p>
           <div class="mode-text">
              <p>1.以跨境电商为核心的数字贸易</p>
              <p>2.以智能应用为核心的数字化应用</p>
              <p>3.以就业为导向的丰富的产业生态</p>
           </div>
         </div>
         <div class="mode-item center">
            <p class="mode-title">教研能力</p>
            <div class="mode-text">
              <p>1.实践经验的师资团队</p>
              <p>2.以智能应用为核心的数字化应用</p>
              <p>3.丰富的优质教学资源</p>
            </div>
         </div>
         <div class="mode-item  right">
            <p class="mode-title ri-title">政府能力</p>
             <div class="mode-text">
                <p>1.政府公关能力</p>
                <p>2.产业基地运营能力</p>
                <p>3.产业导入能力</p>
              </div>
         </div>
       </div>
       <!-- 整体业务体系-->
       <div class="ab-text">
            <div class="ab-title">
                <p>—— 整体业务体系 ——</p>
            </div>
       </div>
      <div class="yz-img-con">
         <img src="../../../assets/images/fr03.png">
      </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
.ab-text{
  margin:40px 0px;
  .ab-title{
    text-align: center;
    font-size: 30px;
    color:#0184d2;
  }
  .ab-desc{
    margin-top:10px;
    text-align: center;
    font-size: 14px;
    color: #656d78;
  }
 }
.yz-img-con{
       width: 100%;
       background: url("../../../assets/images/AI-back.png");
     img{
       width: auto;
       object-fit:none;
       display: block;
       margin: 0 auto;
       padding:50px 0;
     }
}
.mode-top{
     padding:40px 0px;
     width: 1200px;
     margin:0 auto;
     display: flex;
     display: -webkit-flex; /* Safari */
      flex-wrap: wrap;
      justify-content: space-between;
     .mode-item{
       height:240px;
       flex:0 0 30%;
       .mode-title{
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 38px;
         margin-top:75px;
       }
       .mode-text{
          padding:20px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
       }
     }
      .left {
       background: url("../../../assets/images/model01.png") no-repeat;
     }
    .center{
       background: url("../../../assets/images/model02.png") no-repeat;
    }
    .right{
       background: url("../../../assets/images/model03.png") no-repeat;
    }
   }
</style>
